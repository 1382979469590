import authAxios from '@/utils/authAxios';

export default {
  getIncomePayments: ({ start, end }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + '/payment/invoice-payments', {
          start,
          end,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
